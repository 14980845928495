import Vue from 'vue'
import Vuex from 'vuex'
import cart from './modules/cart';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawerVisible: false,
    orderSuccessful: false
  },
  actions: {
    setOrderSuccessful({ commit }, data) {
      commit('SET_ORDER_SUCCESSFUL', data)
    },
    setVisibleDrawer({ commit }) {
      commit('VISIBLE_DRAWER')
    },
    setUnvisibleDrawer({ commit }) {
      commit('UNVISIBLE_DRAWER')
    }
  },
  mutations: {
    SET_ORDER_SUCCESSFUL (state, data) {
      state.orderSuccessful = data
    },
    VISIBLE_DRAWER (state) {
      state.drawerVisible = true
    },
    UNVISIBLE_DRAWER (state) {
      state.drawerVisible = false
    }
  },
  modules: {
    cart // Register the cart module
  }
})
