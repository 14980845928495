// src/store/modules/cart.js
const state = {
  products: [
    { id: 1, title: 'Product 1', quantity: 1, price: 100 },
    { id: 2, title: 'Product 2', quantity: 1, price: 200 },
  ], // Cart products
};

const mutations = {
  ADD_TO_CART(state, product) {
    // Check if the product is already in the cart
    const existingProduct = state.products.find(item => item.id === product.id);
    if (existingProduct) {
      // If it exists, update the quantity
      existingProduct.quantity += product.quantity;
    } else {
      // Otherwise, add the new product
      state.products.push({ ...product });
    }
  },
  UPDATE_QUANTITY(state, { productId, newQuantity }) {
    // Ensure the new quantity is valid (non-negative)
    if (newQuantity < 0) return;

    const product = state.products.find(item => item.id === productId);
    if (product) {
      product.quantity = newQuantity;
    }
  }
};

const actions = {
  addToCart({ commit }, product) {
    commit('ADD_TO_CART', product);
  },
  updateQuantity({ commit }, { productId, newQuantity }) {
    commit('UPDATE_QUANTITY', { productId, newQuantity });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};